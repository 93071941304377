import type { PaywallConfig, PaywallData, Outcome } from '@hubcms/domain-zephr';
import { log } from '@hubcms/utils-monitoring';

import type { PaywallParams } from '../domain/paywall-params';

import { extractAccesswalltypeFromTrackingData } from './extractAccesswalltypeFromTrackingData';
import { extractOutputIdFromTrackingData } from './extractOutputIdFromTrackingData';
import { getAccessWalltypeFallback } from './getAccessWalltypeFallback';
import { getAccessloginshown } from './getAccessloginshown';

export function getPaywallParams(
  paywallConfig: PaywallConfig | null,
  reason: string | null,
  result: PaywallData['result'] | null,
): Partial<PaywallParams> {
  const accessloginshown = getAccessloginshown(result, paywallConfig);

  if (result && result.outcomes) {
    const firstOutcome = result.outcomes.filter(candidate => !!candidate.showTrackingData).at(0);
    if (firstOutcome) {
      const extractedParams = extractPaywallParamsFromTrackingData(firstOutcome);
      return {
        ...extractedParams,
        accessloginshown,
      };
    }
  }

  return {
    accessloginshown,
    accesswalltype: paywallConfig?.tracking?.labelPrefix ?? getAccessWalltypeFallback(reason),
  };
}

function extractPaywallParamsFromTrackingData(firstOutcome: Outcome): Omit<PaywallParams, 'accessloginshown'> | null {
  if (firstOutcome.showTrackingData) {
    try {
      const trackingData = JSON.parse(firstOutcome.showTrackingData);
      const accesswalltype = extractAccesswalltypeFromTrackingData(trackingData) ?? 'none';
      const outputid = extractOutputIdFromTrackingData(trackingData) ?? null;
      return {
        accesswalltype,
        outputid,
      };
    } catch (error) {
      log(`Error processing tracking data for outcome: ${JSON.stringify(firstOutcome)}. Error:`, error);
      return {
        accesswalltype: 'error',
        outputid: null,
      };
    }
  }
  return null;
}
