import type { PaywallConfig, PaywallData } from '@hubcms/domain-zephr';

import type { PaywallParams } from '../domain/paywall-params';

export const getAccessloginshown = (
  result: PaywallData['result'] | null,
  paywallConfig: PaywallConfig | null,
): PaywallParams['accessloginshown'] => {
  if (typeof result?.metadata?.displayFullArticle === 'boolean') {
    return !result.metadata.displayFullArticle;
  }
  if (paywallConfig === null) {
    return false;
  }
  return Object.keys(paywallConfig).length > 0;
};
